
.empty-mask {
  position: absolute;
  z-index: 2000;
  background-color: rgba(255, 255, 255, 0.98);
  margin: 0;
  top: 50%;
  transform: translateY(-50%);
  left: 50%;
  transform: translateX(-50%);
}

.empty-content {
  top: 50%;
  width: 100%;
  text-align: center;
  // position: absolute;
}

.empty-text {
  margin: 3px 0;
  color: #aaa;
  font-size: 13px;
}

.empty-icon {
  display: block;
  width: 150px;
  margin: 0 auto 20px auto;
}
