
.wordcolor {
  color: #14569f;
}
.NavMenu {
  width: 100%;
  height: 191px;
  background-color: #fff;
}
.NavMenu-container {
  // height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 68.75%;
  margin: 0 auto;
}
/**
  上面文字+登陆注册
*/
.NavMenu-Top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 27px;
  .info1 {
    cursor: default;
    width: 598px;
    font-size: 46px;
    font-family: MicrosoftYaHei;
    color: #dc1804;
    white-space: nowrap;
    font-weight: 500;
    display: block;
  }

  .loginAndRegister {
    color: #333333;
    display: flex;
    white-space: nowrap;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    .avatar {
      height: 40px;
      width: 40px;
    }
    span {
      font-size: 16px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      display: block;
      height: 16px;
      line-height: 16px;
    }
    .loginBtn {
      position: relative;
      &::after {
        position: absolute;
        top: 0px;
        right: -13px;
        width: 2px;
        height: 16px;
        content: "";
        background: #333333;
      }
    }
    .registerBtn {
      margin-left: 28px;
    }
  }
}

/* 导航 */
.NavMenu-bottom {
  min-width: 1200px;
  display: flex;
  justify-content: space-between;
  margin-top: 39px;
  margin-bottom: 26px;
  position: relative;
}
.nav-word {
  font-size: 24px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: bold;
  line-height: 36px;
  cursor: pointer;
}

/* 导航显示 */
.showNav {
  width: 100%;
  // line-height: 80px;
}
.showNav-word {
  width: 68.75%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 19px 0px 0px 0px;
}
.showNav-word-wd {
  font-size: 16px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  display: block;
  width: 152px;
  text-align: left;
  color: #ffffff;
  cursor: pointer;
  margin-bottom: 15px;
  &:hover {
    font-weight: 600;
  }
}
.media-NavMenu {
  display: none;
}
.subMenu {
  font-size: 24px;
}
.logo {
  cursor: pointer;
}
.logo > img {
  width: 100%;
}
.el-menu.el-menu--horizontal {
  border-bottom: none;
}
ul {
  display: flex;
  flex-direction: column;
}
.navFooter {
  width: 100%;
  height: 14px;
  background: #14569f;
}
/* 手机屏幕*/
@media screen and (max-width: 768px) {
}
/* 平板电脑*/
@media screen and (min-width: 768px) and (max-width: 992px) {
}
/* 笔记本电脑*/
@media screen and (min-width: 992px) and (max-width: 1200px) {
}

@media screen and (max-width: 1200px) {
  /* 低于1200px出现媒体查询导航条 */
}
/* 台式电脑 */
@media screen and (min-width: 1200px) {
}
