
.popContainer {
  z-index: 1003;
}
.popover {
  display: inline-block;
  position: relative;
  /* 三角 */
  .sjx {
    top: 35px;
    left: 50%;
    position: absolute;
    height: 20px;
    width: 20px;
    background: #458ad6;
    transform: translateX(-50%);
    transform: rotate(45deg);
  }
}
.content-wrapper {
  // top: 45px;
  position: absolute;
  background: #ffffff;
  // box-shadow: 0px 6px 4px 1px rgba(197, 197, 197, 0.5);
  // border-radius: 0px 0px 4px 4px;
  box-shadow: 0px 12px 50px rgba($color: #000000, $alpha: 0.16);
  border-radius: 6px;
  // opacity: 0.8;
  margin-top: 6px;
}
