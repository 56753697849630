
.Footer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  // background: #14569f;
  padding-top: 35px;
  border-top: 1px solid #f1f1f1;
  width: 100%;
  height: 230px;
}
.footer-container {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  height: 186px;
  background: url(../../../assets/images/home-footer.png) no-repeat;
  background-position: 50% 100%;
}
.wz-title {
  font-size: 14px;
  font-family: Medium;
  font-weight: 400;
  color: #2885ff;
}
.wz-title-context {
  margin-top: 23px;
  font-size: 12px;
  font-family: Regular;
  font-weight: 400;
  color: #404040;
  &:hover {
    color: #2885ff;
  }
}
.footer-line {
  /* width: 100%; */
  height: 1px;
  border: 1px solid #f1f1f1;
  border-top: none;
  // margin-top: 53px;
  margin-bottom: 16px;
}
.wx-container2 {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  .wz-title {
    font-size: 14px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: #2885ff;
    margin-bottom: 23px;
  }
}
.wx-img-container {
  width: 119px;
  height: 120px;
  padding: 13px;
  background: #ffffff;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  > img {
    height: 100%;
    width: 100%;
  }
}
.copyright {
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
}
.copyright-left {
  font-size: 12px;
  font-family: SRegular;
  font-weight: 400;
  color: #999999;
  line-height: 30px;
}
.copyright-right {
  font-size: 12px;
  font-family: Regular;
  font-weight: 400;
  color: #999999;
  line-height: 30px;
  cursor: pointer;
}
.container2 {
  .wz-title {
    font-size: 14px;
    font-family: Medium;
    font-weight: 400;
    color: #2885ff;
    cursor: pointer;
  }
  .wz-title-context {
    cursor: pointer;
    font-size: 12px;
    font-family: Regular;
    font-weight: 400;
    color: #404040;
    &:hover {
      color: #2885ff;
    }
  }
}

.container2-wz {
  .wz-title {
    font-size: 14px;
    font-family: Medium;
    font-weight: 400;
    color: #2885ff;
    cursor: pointer;
  }
  .wz-title-context {
    cursor: pointer;
    font-size: 12px;
    font-family: Regular;
    font-weight: 400;
    color: #404040;
    &:hover {
      color: #2885ff;
    }
  }
}

.container2-lx {
  .wz-title {
    font-size: 14px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: #2885ff;
    cursor: pointer;
  }
  .wz-title-context {
    cursor: pointer;
    font-size: 12px;
    font-family: Regular;
    font-weight: 400;
    color: #404040;
    &:hover {
      color: #2885ff;
    }
  }
}

@media screen and (max-width: 992px) {
}
