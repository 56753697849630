.clearfix::before,
.clearfix::after {
	content: '';
	display: table;
	clear: both;
}


.el-button:focus,
.el-button:hover {
	color: #ffffff !important;
	border-color: unset;
	background-color: #14569f;
	border-radius: 4px;
}

.flex-col {
	display: flex;
	flex-direction: column;
}

.flex-row {
	display: flex;
	flex-direction: row;
}

.justify-start {
	display: flex;
	justify-content: flex-start;
}

.justify-center {
	display: flex;
	justify-content: center;
}

.justify-end {
	display: flex;
	justify-content: flex-end;
}

.justify-evenly {
	display: flex;
	justify-content: space-evenly;
}

.justify-around {
	display: flex;
	justify-content: space-around;
}

.justify-between {
	display: flex;
	justify-content: space-between;
}

.align-start {
	display: flex;
	align-items: flex-start;
}

.align-center {
	display: flex;
	align-items: center;
}

.align-end {
	display: flex;
	align-items: flex-end;
}


.messageBox {
	/* width: 150px !important; */
	min-width: 158px !important;
	height: 52px;
	background: #ffffff;
	box-shadow: 0px 12px 50px 0px rgba(0, 0, 0, 0.1600);
	border-radius: 12px;
}

